export const FORM_LOADING_PROBLEM_TEXT =
  "Sorry, there seems to be a problem loading this form. Contact support for more help.";

export enum CustomErrorMessage {
  INVALID_TOKEN = "Login failed - Invalid token",
  SUBSCRIPTION_EXPIRED = "Subscription expired, please contact support@profileatheticmanagment.com",
  INVALID_SUBSCRIPTION_FORMAT = "Invalid subscription Id format, please contact support@profileatheticmanagment.com",
  LOGIN_FAILED = "Login failed, please try again or contact support@profileatheticmanagment.com",
  FORM_SUBMIT_FAILED = "Form submit failed. Please try again.",
  DELETE_SERVICE_FAILED = "Deleting Service Failed",
}

export const ROLE = {
  Admin: "admin",
  Provider: "provider",
  Patient: "patient",
  SuperAdmin: "super-admin",
};

export type ObjectValues<T> = T[keyof T];

export type Role = ObjectValues<typeof ROLE>;

export enum PaymentMethod {
  POS = "pos",
  CASH = "cash",
  VIRTUAL_TERMINAL = "virtual",
}

export enum PaymentUnit {
  PERCENT = "percent",
  FLAT = "flat",
}

export enum RoutePath {
  HOME = "/",
  APPOINTMENTS = "/appointments",
  USERS = "/users",
  PATIENTS = "/patients",
  FORMS = "/forms",
  FORM_ACCESS = "/form-access",
  FORM_SUBMIT = "/form-submit",
  SERVICES = "/services",
  POS_ONBOARDING = "pos-onboarding",
  PAYMENT_HISTORY = "/payment-history",
  COMPANY = "/company",
  SUPER_ADMIN = "/super-admin",
  SETTINGS = "/settings",
  INFO = "/info",
  LOGIN = "/login",
  LOGOUT = "/logout",
  REGISTER = "/register",
}

export const priceDecimals = 2;

export enum SortOption {
  NEWEST = "newest",
  OLDEST = "oldest",
  ALPHA = "alphabetical",
}

export enum ProfileFeatureSentryTag {
  REGISTRATION = "registration",
}

export enum SortEntity {
  CREATED = "createdAt",
  DELETED = "deletedAt",
}

export enum FilterTabState {
  ACTIVE = 0,
  DELETED = 1,
}
